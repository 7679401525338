<template>
    <v-row justify="center">
        <div id="recaptcha-container"></div>

        <v-col cols="12" sm="10" md="8" lg="6">
            <v-card outlined v-if="!smsSent">
                <v-card-text>
                    <div class="text-sm-h4 pb-5">Авторизация</div>
                    <v-text-field label="Номер телефона" v-model="phone"></v-text-field>
                    <v-btn v-if="!processing" v-on:click="isUserExist" color="primary" elevation="1" large>Выслать код</v-btn>
                    <v-progress-circular v-if="processing" :size="40" color="primary" indeterminate></v-progress-circular>
                </v-card-text>
            </v-card>

            <v-card outlined v-else>
                <v-card-text>
                    <div class="text-sm-h4 pb-5">Код подтвержения</div>
                    <v-text-field label="Код подтвержения" v-model="verificationCode"></v-text-field>
                    <v-btn v-if="!processing" v-on:click="login" color="primary" elevation="1" large>Войти</v-btn>
                    <v-progress-circular v-if="processing" :size="40" color="primary" indeterminate></v-progress-circular>

                </v-card-text>
            </v-card>
        </v-col>

    </v-row>
</template>

<script>
    import firebase from "firebase";
    import { db } from '../firebase';


    export default {
        name: "Login",
        data() {
            return {
                phone: '+380',
                smsSent: false,
                verificationCode: '',
                appVerifier: null,
                processing: false
            }
        },
        methods: {
            isUserExist() {
                const that = this;
                that.processing = true;
                db.collection('admins')
                    .where('phone', "==", that.phone.toString())
                    .get()
                    .then(res => {
                        if (!res.empty) {
                            that.sendSMS()
                        } else {
                            that.$store.commit('alert', { message: 'Администратор не найден', type: 'error'});
                            that.processing = false;
                        }
                });
            },

            sendSMS() {
                const that = this;
                firebase.auth().signInWithPhoneNumber(this.phone, this.appVerifier)
                    .then(function (confirmationResult) {
                        window.confirmationResult = confirmationResult;
                        that.smsSent = true
                        that.processing = false;
                    }).catch(function (e) {
                        console.log(e)
                    that.$store.commit('alert', { message: e.message, type: 'error'});
                    that.processing = false;
                });
            },

            async login() {
                const that = this;
                that.processing = true;
                window.confirmationResult.confirm(that.verificationCode).then(function (result) {
                    that.processing = false;
                    that.$store.dispatch('getLanguages')
                    that.$store.dispatch('getCounter')
                    that.$store.commit('user', result.user)
                    that.$router.push('/')
                }).catch(function (e) {
                    console.log(e)
                    that.$store.commit('alert', { message: e.message, type: 'error'});
                    that.processing = false;
                });
            },

            initReCaptcha(){
                setTimeout(()=>{
                    let vm = this;
                    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                        'size': 'invisible',
                        'callback': function(response) {
                            // reCAPTCHA solved, allow signInWithPhoneNumber.
                            // ...
                        },
                        'expired-callback': function() {
                            // Response expired. Ask user to solve reCAPTCHA again.
                            // ...
                        }
                    });
                    //
                    this.appVerifier =  window.recaptchaVerifier
                },1000)
            }
        },
        created(){
            this.initReCaptcha()
        },
    }
</script>

<style scoped>

</style>
